/**
 * Provides central logging implementation
 * Can use this to filter messages or send them to CloudWatch
 */
export default class LoggingService {
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }
    // tslint:disable-next-line: variable-name
    private static _instance: LoggingService;

    public shopOrigin: string = "";

    private logTrace: boolean = true;

    private constructor() {}

    debug(message: string, properties?: { [key: string]: string }) {
        if (this.logTrace) {
            if (properties) {
                console.debug(message, properties);
            } else {
                console.debug(message);
            }
        }
    }

    error(message: string, properties?: { [key: string]: string }) {
        if (properties) {
            console.error(message, properties);
        } else {
            console.error(message);
        }
    }
}
