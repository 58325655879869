import ConfigurationService from "./ConfigurationService";
import { IAttributes } from "../models/IAttributes";

export default class ApiService {
    constructor(private config: ConfigurationService) {}

    public async pauseRecording(contact: connect.Contact) {
        const token = contact?.getAttributes().CCP_TOKEN;
        if (!token) {
            throw new Error("CCP_TOKEN attribute is required to use API");
        }

        const initialContactId = contact?.getOriginalContactId() || contact?.getContactId();

        const body = {
            contactId: initialContactId,
        };

        const response = await fetch(this.config.pauseRecordingUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token.value}`,
            },
            body: JSON.stringify(body),
        });

        if (response.status !== 200) {
            const err = await response.json();
            throw new Error(err);
        }
    }

    public async resumeRecording(contact: connect.Contact) {
        const token = contact?.getAttributes().CCP_TOKEN;
        if (!token) {
            throw new Error("CCP_TOKEN attribute is required to use API");
        }

        const initialContactId = contact?.getOriginalContactId() || contact?.getContactId();

        const body = {
            contactId: initialContactId,
        };

        const response = await fetch(this.config.resumeRecordingUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token.value}`,
            },
            body: JSON.stringify(body),
        });

        if (response.status !== 200) {
            const err = await response.json();
            throw new Error(err);
        }
    }

    public async stopRecording(contact: connect.Contact) {
        const token = contact?.getAttributes().CCP_TOKEN;
        if (!token) {
            throw new Error("CCP_TOKEN attribute is required to use API");
        }

        const initialContactId = contact?.getOriginalContactId() || contact?.getContactId();

        const body = {
            contactId: initialContactId,
        };

        const response = await fetch(this.config.stopRecordingUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token.value}`,
            },
            body: JSON.stringify(body),
        });

        if (response.status !== 200) {
            const err = await response.json();
            throw new Error(err);
        }
    }
}
