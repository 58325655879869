import React from "react";
import "./App.css";
import Ccp from "./components/Ccp";
import ConfigurationService from "./services/ConfigurationService";

interface IState {
    config: ConfigurationService;
    isRecordingStopped: boolean;
    isInCall: boolean;
}
export default class App extends React.Component<{}, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            config: undefined as any,
            isInCall: false,
            isRecordingStopped: false,
        };
    }

    componentDidMount() {
        const cfg = new ConfigurationService();
        cfg.load().then(() => this.setState({ config: cfg }));
    }

    render() {
        if (!this.state.config) {
            return null;
        }
        return (
            <div className="App">
                <Ccp config={this.state.config} />
            </div>
        );
    }
}
