import moment from "moment";
import React from "react";
import ConfigurationService from "../services/ConfigurationService";

interface IProps {
    config: ConfigurationService;
}
interface IState {
    buildTime: string;
    className: string;
}

export default class BuildTime extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            className: "",
            buildTime: "",
        };
    }

    componentDidMount() {
        const config = this.props.config;
        const envClass = config.environment === "production" ? "" : "transparent";

        const time = new Date(config.buildTime);
        this.setState({
            className: envClass,
            buildTime: "Build Time: " + moment(time).format("M/D/YY h:mm A"),
        });
    }

    render() {
        return <div className={this.state.className}>{this.state.buildTime}</div>;
    }
}
