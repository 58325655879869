import LoggingService from "./LoggingService";
import { environment } from "../config";

interface IDispositionConfig {
    enabled: boolean;
    required: boolean;
    attributeName: string;
    defaultDisposition: string;
    options: {
        [name: string]: string[];
    };
}

interface IConfig {
    environment: string;
    buildTime: number;
    ccpUrl: string;
    ccpRegion: string;
    samlSsoUrl: string;

    // CCP_TOKEN attribute must be set to use
    pauseRecordingUrl: string;
    resumeRecordingUrl: string;
    stopRecordingUrl: string;
}

export default class ConfigurationService implements IConfig {
    private logger: LoggingService = LoggingService.Instance;
    private cfg: IConfig = null as any;

    public async load() {
        if (!this.cfg) {
            const response = await fetch("config.json");
            this.cfg = await response.json();
            this.logger.debug("Config Loaded");
        }
    }

    get environment(): string {
        return this.cfg.environment;
    }

    get buildTime(): number {
        return this.cfg.buildTime;
    }

    get ccpUrl(): string {
        return environment.ccpUrl;
    }

    get ccpRegion(): string {
        return environment.ccpRegion;
    }

    get samlSsoUrl(): string {
        return environment.samlSsoUrl ? environment.samlSsoUrl : "";
    }

    get pauseRecordingUrl(): string {
        return this.cfg.pauseRecordingUrl;
    }

    get resumeRecordingUrl(): string {
        return this.cfg.resumeRecordingUrl;
    }

    get stopRecordingUrl(): string {
        return environment.stopRecordingUrl;
    }

    isLocal(): boolean {
        return this.environment === "local";
    }

    isV2(): boolean {
        return this.ccpUrl.includes("ccp-v2");
    }
}
